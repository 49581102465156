import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './modules/core/services/authentication.service';

const SESSION_PARAM_NAME = 'App_SessionId';

@Component({
  selector: 'tnev-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @HostBinding('class.tdoe-display-block') loadingMessage = true;
  @HostBinding('class.h-100') height = true;

  public showMenu = false;
  public user$;

  constructor(public svAuthentication: AuthenticationService) {
    this.user$ = this.svAuthentication.user$.asObservable();
  }

  ngOnInit() {
    // this.svAuthentication.loadUser().subscribe(
    //   user => {},
    //   e => {
    //     this.svRouter.navigate(['/unauthorized']);
    //     return e;
    //   }
    // );
  }
}
