import { PipeTransform } from "@angular/core";
var NoBlankPipe = /** @class */ (function () {
    function NoBlankPipe() {
    }
    NoBlankPipe.prototype.transform = function (value, args) {
        if (typeof value === "undefined" || value === null || value === -1) {
            return "--";
        }
        return value;
    };
    return NoBlankPipe;
}());
export { NoBlankPipe };
