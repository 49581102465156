import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { TdoeAuthenticationService } from '@tdoe/tdoe-authentication-library';
import { IOrionUser } from '@tdoe/tdoe-authentication-library';

import { IEVPUser } from 'app/models/user.model';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
  public initalized$: Observable<boolean>;
  public user$: BehaviorSubject<IEVPUser> = new BehaviorSubject(null);

  constructor(
    private svTDOEAuthService: TdoeAuthenticationService) {
    this.initalized$ = this.svTDOEAuthService.initialized$.asObservable();
    this.svTDOEAuthService.user$.asObservable()
      .pipe(
        map((user: IOrionUser) => {
          if (!user) {
            return user;
          }

          // TODO: Remove (Debugging purposes)
          // user.associatedOrganizations.splice(2, 1);
          // user.localEducationAgencyIds.splice(2, 1);
          // user.organizations.splice(1, 1);

          console.log(user.searchableOrganizations);

          const newUser: IEVPUser = {
            ...user,
            isStateUser: user.localEducationAgencyIds.indexOf(47) > -1,
            orgLookup: user.searchableOrganizations
          };

          return newUser;
        }),
        catchError(e => {
          console.error(e);
          throw e;
        })
      )
      .subscribe((user: IEVPUser) => {
        console.log(user);
        this.user$.next(user);
      });
  }

  /**
   * @param districtId {number} DistrictID you are checking
   * @param schoolId {number} SchoolID you are checking
   */
  hasAccess(districtId: number, schoolId?: number) {
    return this.svTDOEAuthService.hasLeaAccess(districtId, schoolId);
  }
}
