/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./application-error.component";
var styles_ApplicationErrorComponent = [];
var RenderType_ApplicationErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ApplicationErrorComponent, data: {} });
export { RenderType_ApplicationErrorComponent as RenderType_ApplicationErrorComponent };
export function View_ApplicationErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "tdoe-text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "tdoe-color-red"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Application Error"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "lead mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You will need to close out of this tab and try to launch the application again..."])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [["color", "primary"], ["href", "https://tneducation.net"], ["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatAnchor_0, i1.RenderType_MatAnchor)), i0.ɵdid(6, 180224, null, 0, i2.MatAnchor, [i3.FocusMonitor, i0.ElementRef, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-external-link-square-alt"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\u00A0Orion / SSO"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "a", [["href", "https://doe1.tn.gov/Authorize/login.aspx"], ["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatAnchor_0, i1.RenderType_MatAnchor)), i0.ɵdid(10, 180224, null, 0, i2.MatAnchor, [i3.FocusMonitor, i0.ElementRef, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(11, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-external-link-square-alt"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\u00A0Authorization Management"])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" * This probably occured because a service to fetch data is down. "]))], function (_ck, _v) { var currVal_4 = "primary"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 6).disabled ? (0 - 1) : (i0.ɵnov(_v, 6).tabIndex || 0)); var currVal_1 = (i0.ɵnov(_v, 6).disabled || null); var currVal_2 = i0.ɵnov(_v, 6).disabled.toString(); var currVal_3 = (i0.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = (i0.ɵnov(_v, 10).disabled ? (0 - 1) : (i0.ɵnov(_v, 10).tabIndex || 0)); var currVal_6 = (i0.ɵnov(_v, 10).disabled || null); var currVal_7 = i0.ɵnov(_v, 10).disabled.toString(); var currVal_8 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ApplicationErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "application-error", [], null, null, null, View_ApplicationErrorComponent_0, RenderType_ApplicationErrorComponent)), i0.ɵdid(1, 49152, null, 0, i5.ApplicationErrorComponent, [], null, null)], null, null); }
var ApplicationErrorComponentNgFactory = i0.ɵccf("application-error", i5.ApplicationErrorComponent, View_ApplicationErrorComponent_Host_0, {}, {}, []);
export { ApplicationErrorComponentNgFactory as ApplicationErrorComponentNgFactory };
