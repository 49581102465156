import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';

import { fade } from 'app/modules/shared/animations/fade-in.animation';

@Component({
  selector: 'tnev-loading-message',
  templateUrl: './loading-message.component.html',
  styleUrls: ['./loading-message.component.scss'],
  animations: [fade],
  encapsulation: ViewEncapsulation.None
})
export class LoadingMessageComponent implements OnInit {
  @HostBinding('class.tn-loading-message') loadingMessage = true;
  @HostBinding('@fade') fade = true;

  constructor() { }

  ngOnInit() {
  }

}
