import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class ApiHelperService {
  constructor() {}

  get AuthHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    const apiToken = sessionStorage.getItem('aToken');
    const userToken = sessionStorage.getItem('cToken');
    const clientId = sessionStorage.getItem('clientId');
    /** No need to include Content-Type in Angular 4 */
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${apiToken}`);
    headers.append('User', `${userToken}`);
    headers.append('ClientId', `${clientId}`);

    return headers;
  }

  handleError(error: any) {
    return this.getCleanError(error);
  }

  getCleanError(error: any): { error: string; code: number } {
    let message = null;
    let status = error.status || 500;

    if (!error) {
      message = 'Error peforming action.';
    }

    if (error['status']) {
      status = error['status'];
    }

    if (!message && error['message']) {
      message = error['message'];
    }

    if (!message && error['statusText']) {
      message = error['statusText'];
    }

    if (status === 401) {
      message = 'You are either unauthorized to access this data or your session has timed out.';
    }

    return { error: message, code: status };
  }
}
