<div class="tdoe-text-center">
    <h1 class="tdoe-color-red">Unauthorized</h1>
    <p class="lead mb-5">You will need to login again...</p>
    <a mat-raised-button color="primary" href="https://tneducation.net"><i class="fas fa-external-link-square-alt" aria-hidden="true"></i>&nbsp;Orion / SSO</a>
    <a mat-raised-button href="https://doe1.tn.gov/Authorize/login.aspx"><i class="fas fa-external-link-square-alt" aria-hidden="true"></i>&nbsp;Authorization Management</a>
    <br>
    <br>
    <small class="text-muted">
        * This probably occurred because your session has timed out.
    </small>
</div>