import { Component, Inject, HostBinding } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tnevp-dialog-message',
  templateUrl: './dialog-message.component.html',
})
export class DialogMessageComponent {
  @HostBinding('class.tnevp-dialog-message') c = true;

  constructor(public dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

}
