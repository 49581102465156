import { HttpHeaders } from '@angular/common/http';
var ApiHelperService = /** @class */ (function () {
    function ApiHelperService() {
    }
    Object.defineProperty(ApiHelperService.prototype, "AuthHeaders", {
        get: function () {
            var headers = new HttpHeaders();
            var apiToken = sessionStorage.getItem('aToken');
            var userToken = sessionStorage.getItem('cToken');
            var clientId = sessionStorage.getItem('clientId');
            /** No need to include Content-Type in Angular 4 */
            headers.append('Accept', 'application/json');
            headers.append('Authorization', "Bearer " + apiToken);
            headers.append('User', "" + userToken);
            headers.append('ClientId', "" + clientId);
            return headers;
        },
        enumerable: true,
        configurable: true
    });
    ApiHelperService.prototype.handleError = function (error) {
        return this.getCleanError(error);
    };
    ApiHelperService.prototype.getCleanError = function (error) {
        var message = null;
        var status = error.status || 500;
        if (!error) {
            message = 'Error peforming action.';
        }
        if (error['status']) {
            status = error['status'];
        }
        if (!message && error['message']) {
            message = error['message'];
        }
        if (!message && error['statusText']) {
            message = error['statusText'];
        }
        if (status === 401) {
            message = 'You are either unauthorized to access this data or your session has timed out.';
        }
        return { error: message, code: status };
    };
    return ApiHelperService;
}());
export { ApiHelperService };
