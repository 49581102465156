import { Router } from '@angular/router';
import { AuthenticationService } from 'app/modules/core/services/authentication.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(svAuthentication, svRouter) {
        this.svAuthentication = svAuthentication;
        this.svRouter = svRouter;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var user = this.svAuthentication.user$.getValue();
        var auth = !!user;
        if (!auth) {
            this.svRouter.navigate(['/application-error']);
        }
        return auth;
    };
    return AuthGuardService;
}());
export { AuthGuardService };
