import {
  Injectable
} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';


import {
  User
} from 'app/models/user.model';
import { AuthenticationService } from 'app/modules/core/services/authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private svAuthentication: AuthenticationService, private svRouter: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.svAuthentication.user$.getValue();
    const auth = !!user;

    if (!auth) {
      this.svRouter.navigate(['/application-error']);
    }

    return auth;
  }
}
