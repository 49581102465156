import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { TdoeAuthenticationLibraryModule } from '@tdoe/tdoe-authentication-library';
import { TdoeAuthenticationService } from '@tdoe/tdoe-authentication-library';

import { AuthenticationService } from './services/authentication.service';
import { environment } from 'environments/environment';

const BASE_MODULES = [

];

const CORE_COMPONENTS = [

];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    TdoeAuthenticationLibraryModule.forRoot({
      environment: environment.envName,
      oidcConfigPath: `assets/oidc/auth-client.${environment.envName}.json`,
      debug: (environment.envName === 'local'),
      clientId: environment.client_id
    }),

    BASE_MODULES
  ],
  declarations: [CORE_COMPONENTS],
  exports: [BASE_MODULES, CORE_COMPONENTS],
  providers: [
    TdoeAuthenticationService,
    AuthenticationService
  ]
})
export class CoreModule {
}
