import { Injectable } from "@angular/core";

@Injectable()
export class ToastService {
  private _toasts = [];
  private useCloseButton = true;
  private dismissTime = 4000; // 4 sec

  constructor() {}

  pop(type: string, title: string, message: string): string {
    return "";
  }

  dismiss(id: string) {}
}
