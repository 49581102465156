import { HttpClient } from '@angular/common/http';
var SESSION_PARAM_NAME = 'App_SessionId';
var AppConfig = /** @class */ (function () {
    function AppConfig(http) {
        this.http = http;
    }
    AppConfig.prototype.load = function () {
        var sessionId = this.getParameterByName(SESSION_PARAM_NAME);
        if (sessionId) {
            sessionStorage.setItem(SESSION_PARAM_NAME, sessionId);
        }
    };
    AppConfig.prototype.getParameterByName = function (name) {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };
    return AppConfig;
}());
export { AppConfig };
