import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';

import { VerificationService } from 'app/enrollment-verification/services/verification.service';
import { MetricDescription } from 'app/models/metric-description.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { IMetricGroup, IMetricValue } from '../../models/metric-report.model';

@Component({
  selector: 'tnev-metric-group',
  templateUrl: './metric-group.component.html'
})
export class MetricGroupComponent {
  @HostBinding('class.tdoe-display-block') loadingMessage = true;
  @HostBinding('class.tnev-metric-group') mg = true;
  @Input('maxDate') public maxDate: Date;
  @Input('group') public group: IMetricGroup;
  @Input('nValues') public nValues: number;
  @Input('tClass') public tClass: string;

  @Output('download') public download = new EventEmitter<{groupId: number, metricId: number, index: number}>();

  constructor(public svDialog: MatDialog, private svVerificationService: VerificationService) {}

  genArray(n: number) {
    return Array(n).fill(1); // [0,1,2,3,4]
  }

  open(value: IMetricValue) {
    this.svDialog.open(DialogMessageComponent, {
      data: {
        name: value.metricName,
        description: value.metricDescriptiveText
      },
      maxWidth: 1024,
      minWidth: 200
    });
  }

  openGroup(value: IMetricGroup) {
    this.svDialog.open(DialogMessageComponent, {
      data: {
        name: value.groupName,
        description: value.groupDescriptiveText
      },
      maxWidth: 1024,
      minWidth: 200
    });
  }

  downloadStudents(groupId: number, metricId: number, index: number) {
    console.log(groupId, metricId, index);
    this.download.emit({
      groupId,
      metricId,
      index
    });
  }
}
