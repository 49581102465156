// Dev enviroment (for qa etc)

export const environment = {
  production: true,
  envName: 'dev',
  client_id: 'evp-portal',
  endpoint: 'https://dev-assessments-api.tneducation.net/api',

  formatter: {
    DATE: 'YYYY-MM-DD'
  }
};
