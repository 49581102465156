import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableHeader'
})
export class TableHeaderPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let nValue = value.replace(/_/g, ' ');

    nValue = nValue.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase())

    return nValue;
  }

}
