import { EventEmitter } from '@angular/core';
import { VerificationService } from 'app/enrollment-verification/services/verification.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
var MetricGroupComponent = /** @class */ (function () {
    function MetricGroupComponent(svDialog, svVerificationService) {
        this.svDialog = svDialog;
        this.svVerificationService = svVerificationService;
        this.loadingMessage = true;
        this.mg = true;
        this.download = new EventEmitter();
    }
    MetricGroupComponent.prototype.genArray = function (n) {
        return Array(n).fill(1); // [0,1,2,3,4]
    };
    MetricGroupComponent.prototype.open = function (value) {
        this.svDialog.open(DialogMessageComponent, {
            data: {
                name: value.metricName,
                description: value.metricDescriptiveText
            },
            maxWidth: 1024,
            minWidth: 200
        });
    };
    MetricGroupComponent.prototype.openGroup = function (value) {
        this.svDialog.open(DialogMessageComponent, {
            data: {
                name: value.groupName,
                description: value.groupDescriptiveText
            },
            maxWidth: 1024,
            minWidth: 200
        });
    };
    MetricGroupComponent.prototype.downloadStudents = function (groupId, metricId, index) {
        console.log(groupId, metricId, index);
        this.download.emit({
            groupId: groupId,
            metricId: metricId,
            index: index
        });
    };
    return MetricGroupComponent;
}());
export { MetricGroupComponent };
