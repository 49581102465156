import { MatDialogRef } from '@angular/material/dialog';
var DialogMessageComponent = /** @class */ (function () {
    function DialogMessageComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.c = true;
    }
    return DialogMessageComponent;
}());
export { DialogMessageComponent };
