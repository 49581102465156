import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  TDOEModule,
  TDOESidebarLayoutModule,
  TDOEHeaderBarModule
} from '@tdoe/tdoe-component-library';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { SettingsComponent } from './settings/settings.component';
import { UnauthorizedComponent } from 'app/error-pages/unauthorized/unauthorized.component';
import { ApplicationErrorComponent } from 'app/error-pages/application-error/application-error.component';
import { AuthGuardService } from 'app/modules/shared/services/auth-guard.service';
import { DialogMessageComponent } from './components/dialog-message/dialog-message.component';
import { AppConfig } from './app.config';

import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from './modules/core/core.module';

@NgModule({
  entryComponents: [DialogMessageComponent],
  declarations: [
    AppComponent,
    SettingsComponent,
    UnauthorizedComponent,
    ApplicationErrorComponent,
    DialogMessageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    CoreModule,
    SharedModule.forRoot(),
    TDOEModule.forRoot(),
    TDOESidebarLayoutModule,
    TDOEHeaderBarModule,

    AppRoutingModule
  ],
  providers: [
    AuthGuardService,
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
