import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TdoeAuthGuard } from '@tdoe/tdoe-authentication-library';
import { SettingsComponent } from 'app/settings/settings.component';

import { environment } from '../environments/environment';
import { UnauthorizedComponent } from 'app/error-pages/unauthorized/unauthorized.component';
import { ApplicationErrorComponent } from 'app/error-pages/application-error/application-error.component';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Profile', icon: 'fa-list' }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: { title: 'Error - Unauthorized', icon: 'fa-warning' }
  },
  {
    path: 'application-error',
    component: ApplicationErrorComponent,
    data: { title: 'Error - Unauthorized', icon: 'fa-warning' }
  },
  {
    path: 'enrollment-verification',
    data: { title: 'Enrollment Verification', icon: 'fa-check-square-o' },
    canActivate: [TdoeAuthGuard],
    loadChildren: './enrollment-verification/enrollment-verification.module#EnrollmentVerificationModule'
  },
  { path: '', redirectTo: 'enrollment-verification', pathMatch: 'full' },
  { path: '**', redirectTo: 'enrollment-verification', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: !environment.production })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
