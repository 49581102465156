import { Routes } from '@angular/router';
import { TdoeAuthGuard } from '@tdoe/tdoe-authentication-library';
import { SettingsComponent } from 'app/settings/settings.component';
import { UnauthorizedComponent } from 'app/error-pages/unauthorized/unauthorized.component';
import { ApplicationErrorComponent } from 'app/error-pages/application-error/application-error.component';
var ɵ0 = { title: 'Profile', icon: 'fa-list' }, ɵ1 = { title: 'Error - Unauthorized', icon: 'fa-warning' }, ɵ2 = { title: 'Error - Unauthorized', icon: 'fa-warning' }, ɵ3 = { title: 'Enrollment Verification', icon: 'fa-check-square-o' };
var routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        data: ɵ0
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: ɵ1
    },
    {
        path: 'application-error',
        component: ApplicationErrorComponent,
        data: ɵ2
    },
    {
        path: 'enrollment-verification',
        data: ɵ3,
        canActivate: [TdoeAuthGuard],
        loadChildren: './enrollment-verification/enrollment-verification.module#EnrollmentVerificationModule'
    },
    { path: '', redirectTo: 'enrollment-verification', pathMatch: 'full' },
    { path: '**', redirectTo: 'enrollment-verification', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
