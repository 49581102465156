import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'application-error',
  templateUrl: './application-error.component.html'
})
export class ApplicationErrorComponent {

  constructor() { }
}
