var ToastService = /** @class */ (function () {
    function ToastService() {
        this._toasts = [];
        this.useCloseButton = true;
        this.dismissTime = 4000; // 4 sec
    }
    ToastService.prototype.pop = function (type, title, message) {
        return "";
    };
    ToastService.prototype.dismiss = function (id) { };
    return ToastService;
}());
export { ToastService };
