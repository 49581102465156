import { OnInit } from '@angular/core';
import { AuthenticationService } from './modules/core/services/authentication.service';
var SESSION_PARAM_NAME = 'App_SessionId';
var AppComponent = /** @class */ (function () {
    function AppComponent(svAuthentication) {
        this.svAuthentication = svAuthentication;
        this.loadingMessage = true;
        this.height = true;
        this.showMenu = false;
        this.user$ = this.svAuthentication.user$.asObservable();
    }
    AppComponent.prototype.ngOnInit = function () {
        // this.svAuthentication.loadUser().subscribe(
        //   user => {},
        //   e => {
        //     this.svRouter.navigate(['/unauthorized']);
        //     return e;
        //   }
        // );
    };
    return AppComponent;
}());
export { AppComponent };
