import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

const SESSION_PARAM_NAME = 'App_SessionId';

@Injectable()
export class AppConfig {
  constructor(private http: HttpClient) {}

  public load() {
    const sessionId = this.getParameterByName(SESSION_PARAM_NAME);

    if (sessionId) {
        sessionStorage.setItem(SESSION_PARAM_NAME, sessionId);
    }
  }

  private getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
