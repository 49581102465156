/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@tdoe/tdoe-component-library/tdoe-tdoe-component-library.ngfactory";
import * as i2 from "@tdoe/tdoe-component-library";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./modules/core/services/authentication.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tdoe-header-bar", [["class", "animated slideInDown delay-2s"]], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "img", [["src", "https://firebasestorage.googleapis.com/v0/b/tdoe-component-library.appspot.com/o/tn-logo_32x32.png?alt=media&token=9a0ca470-83da-434f-b445-94f20dfa7abd"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" \u00A0\u00A0TCAP Verification "])), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf.displayName; _ck(_v, 6, 0, currVal_0); }); }
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "tdoe-page-content animated fadeIn"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.user$)); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "animated bounce infinite"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Loading Application... "]))], null, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["loadingUser", 2]], null, 0, null, View_AppComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.svAuthentication.initalized$; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tnev-root", [], [[2, "tdoe-display-block", null], [2, "h-100", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i5.AppComponent, [i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).loadingMessage; var currVal_1 = i0.ɵnov(_v, 1).height; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AppComponentNgFactory = i0.ɵccf("tnev-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
