import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { Ng2OrderModule } from 'ng2-order-pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ApiHelperService } from './services/api-helper.service';
import { ToastService } from './services/toast.service';

import { MetricGroupComponent } from 'app/components/metric-group/metric-group.component';
import { LoadingMessageComponent } from 'app/components/loading-message/loading-message.component';
import { NoBlankPipe } from './pipes/no-blank.pipe';
import { KeysPipe } from 'app/modules/shared/pipes/keys.pipe';
import { TableHeaderPipe } from 'app/modules/shared/pipes/table-header.pipe';


// export class CustomToastOptions extends ToastOptions {
//   animate = 'fade'; // you can override any options available
//   newestOnTop = false;
//   showCloseButton = true;
//   positionClass = 'toast-bottom-center';
//   mouseoverTimerStop = false;
// }

const SharedComponents = [
  MetricGroupComponent,
  LoadingMessageComponent,
  NoBlankPipe,
  KeysPipe,
  TableHeaderPipe
];

const SharedModules = [
  ClickOutsideModule,
  Ng2OrderModule,
  //
  FontAwesomeModule,
  MatListModule,
  MatDialogModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonModule,
  MatCardModule,
  MatSnackBarModule,
  MatTableModule,

  FlexLayoutModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModules
  ],
  declarations: [SharedComponents],
  exports: [SharedModules, SharedComponents]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        // { provide: ToastOptions, useClass: CustomToastOptions },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: { appearance: 'outline' }
        },
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: { horizontalPosition: 'center', verticalPosition: 'bottom' }
        },
        //
        ApiHelperService,
        ToastService
      ]
    };
  }
}
