import { Component } from '@angular/core';

@Component({
  selector: 'tnev-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent{

  constructor() { }

}
