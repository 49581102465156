/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./dialog-message.component";
var styles_DialogMessageComponent = [];
var RenderType_DialogMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogMessageComponent, data: {} });
export { RenderType_DialogMessageComponent as RenderType_DialogMessageComponent };
export function View_DialogMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "tdoe-text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "button", [["mat-icon-button", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).dialogRef.close(i0.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(2, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i0.ElementRef, i2.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), i0.ɵdid(3, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i0.ɵdid(5, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer], { iconProp: [0, "iconProp"], fixedWidth: [1, "fixedWidth"] }, null), i0.ɵpad(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 2, "h4", [["class", "tn-metric-name mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(8, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i0.ElementRef, i2.MatDialog], null, null), (_l()(), i0.ɵted(9, null, [" ", "\n"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "mat-dialog-content", [["class", "tn-metric-description mat-dialog-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "tdoe-text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["mat-button", ""], ["tabindex", "-1"]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).dialogRef.close(i0.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(16, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i0.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(17, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var currVal_4 = "button"; var currVal_5 = null; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_7 = _ck(_v, 6, 0, "far", "times-circle"); var currVal_8 = true; _ck(_v, 5, 0, currVal_7, currVal_8); _ck(_v, 8, 0); var currVal_16 = null; _ck(_v, 16, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 2).ariaLabel || null); var currVal_1 = i0.ɵnov(_v, 2).type; var currVal_2 = (i0.ɵnov(_v, 3).disabled || null); var currVal_3 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = i0.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_6); var currVal_9 = i0.ɵnov(_v, 8).id; _ck(_v, 7, 0, currVal_9); var currVal_10 = ((_co.data == null) ? null : _co.data.name); _ck(_v, 9, 0, currVal_10); var currVal_11 = ((_co.data == null) ? null : _co.data.description); _ck(_v, 10, 0, currVal_11); var currVal_12 = (i0.ɵnov(_v, 16).ariaLabel || null); var currVal_13 = i0.ɵnov(_v, 16).type; var currVal_14 = (i0.ɵnov(_v, 17).disabled || null); var currVal_15 = (i0.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_DialogMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tnevp-dialog-message", [], [[2, "tnevp-dialog-message", null]], null, null, View_DialogMessageComponent_0, RenderType_DialogMessageComponent)), i0.ɵdid(1, 49152, null, 0, i9.DialogMessageComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).c; _ck(_v, 0, 0, currVal_0); }); }
var DialogMessageComponentNgFactory = i0.ɵccf("tnevp-dialog-message", i9.DialogMessageComponent, View_DialogMessageComponent_Host_0, {}, {}, []);
export { DialogMessageComponentNgFactory as DialogMessageComponentNgFactory };
