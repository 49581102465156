import { PipeTransform } from '@angular/core';
var TableHeaderPipe = /** @class */ (function () {
    function TableHeaderPipe() {
    }
    TableHeaderPipe.prototype.transform = function (value, args) {
        var nValue = value.replace(/_/g, ' ');
        nValue = nValue.toLowerCase().replace(/(^| )(\w)/g, function (s) { return s.toUpperCase(); });
        return nValue;
    };
    return TableHeaderPipe;
}());
export { TableHeaderPipe };
