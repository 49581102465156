import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "noBlank"
})
export class NoBlankPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value === "undefined" || value === null || value === -1) {
      return "--";
    } 
    return value;
  }
}
