import { OnInit } from '@angular/core';
var LoadingMessageComponent = /** @class */ (function () {
    function LoadingMessageComponent() {
        this.loadingMessage = true;
        this.fade = true;
    }
    LoadingMessageComponent.prototype.ngOnInit = function () {
    };
    return LoadingMessageComponent;
}());
export { LoadingMessageComponent };
