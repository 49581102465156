<div class="tdoe-text-center">
  <h1 class="tdoe-color-red">Application Error</h1>
  <p class="lead mb-5">You will need to close out of this tab and try to launch the application again...</p>
  <a mat-raised-button color="primary" href="https://tneducation.net"><i class="fas fa-external-link-square-alt" aria-hidden="true"></i>&nbsp;Orion / SSO</a>
  <a mat-raised-button href="https://doe1.tn.gov/Authorize/login.aspx"><i class="fas fa-external-link-square-alt" aria-hidden="true"></i>&nbsp;Authorization Management</a>
  <br>
  <br>
  <small class="text-muted">
      * This probably occured because a service to fetch data is down.
  </small>
</div>