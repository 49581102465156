<div class="tdoe-page-content animated fadeIn" *ngIf="svAuthentication.initalized$">
  <tdoe-header-bar *ngIf="user$ | async as user" class="animated slideInDown delay-2s">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/tdoe-component-library.appspot.com/o/tn-logo_32x32.png?alt=media&amp;token=9a0ca470-83da-434f-b445-94f20dfa7abd">
    &nbsp;&nbsp;TCAP
    Verification
    <div class="spacer"></div>
    <small>
      {{ user.displayName }}
    </small>
  </tdoe-header-bar>
  <!-- <tnev-loading-message *ngIf="!svAuthentication.isReady && svAuthentication.isError">
        <span class="text-danger">Fatal Error...</span>
        <p class="text-muted">
          <small>You will need to refresh the page and try again.</small>
        </p>
      </tnev-loading-message> -->
  <router-outlet></router-outlet>
</div>
<ng-template #loadingUser>
  <div class="animated bounce infinite">
    Loading Application...
  </div>
</ng-template>