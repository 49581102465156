<div class="tdoe-text-right">
  <button type="button" mat-icon-button [mat-dialog-close]="null" tabindex="-1">
    <fa-icon [icon]="['far', 'times-circle']" [fixedWidth]="true"></fa-icon>
  </button>
</div>

<h4 mat-dialog-title class="tn-metric-name">
  {{ data?.name }}
</h4>

<mat-dialog-content class="tn-metric-description" [innerHTML]="data?.description"></mat-dialog-content>

<br/>
<br/>
<div class="tdoe-text-right">
  <button mat-button [mat-dialog-close]="null" tabindex="-1">Close</button>
</div>
