var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject } from 'rxjs';
import { TdoeAuthenticationService } from '@tdoe/tdoe-authentication-library';
import { map, catchError } from 'rxjs/operators';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(svTDOEAuthService) {
        var _this = this;
        this.svTDOEAuthService = svTDOEAuthService;
        this.user$ = new BehaviorSubject(null);
        this.initalized$ = this.svTDOEAuthService.initialized$.asObservable();
        this.svTDOEAuthService.user$.asObservable()
            .pipe(map(function (user) {
            if (!user) {
                return user;
            }
            // TODO: Remove (Debugging purposes)
            // user.associatedOrganizations.splice(2, 1);
            // user.localEducationAgencyIds.splice(2, 1);
            // user.organizations.splice(1, 1);
            console.log(user.searchableOrganizations);
            var newUser = __assign({}, user, { isStateUser: user.localEducationAgencyIds.indexOf(47) > -1, orgLookup: user.searchableOrganizations });
            return newUser;
        }), catchError(function (e) {
            console.error(e);
            throw e;
        }))
            .subscribe(function (user) {
            console.log(user);
            _this.user$.next(user);
        });
    }
    /**
     * @param districtId {number} DistrictID you are checking
     * @param schoolId {number} SchoolID you are checking
     */
    AuthenticationService.prototype.hasAccess = function (districtId, schoolId) {
        return this.svTDOEAuthService.hasLeaAccess(districtId, schoolId);
    };
    return AuthenticationService;
}());
export { AuthenticationService };
